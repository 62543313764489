

@supports (font-variation-settings: "wght" 100) {
  * {
    font-family: 'Poppins';
    font-weight: 100;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 200) {
  * {
    font-family: 'Poppins';
    font-weight: 200;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 300) {
  * {
    font-family: 'Poppins';
    font-weight: 300;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 400) {
  * {
    font-family: 'Poppins';
    font-weight: 400;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 500) {
  * {
    font-family: 'Poppins';
    font-weight: 500;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 600) {
  * {
    font-family: 'Poppins';
    font-weight: 600;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 700) {
  * {
    font-family: 'Poppins';
    font-weight: 700;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 800) {
  * {
    font-family: 'Poppins';
    font-weight: 800;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 900) {
  * {
    font-family: 'Poppins';
    font-weight: 900;
    font-display: swap;
  }
}

@supports (font-variation-settings: "wght" 100) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 100;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 200) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 200;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 300) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 300;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 400) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 500) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 500;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 600) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 700) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 800) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 800;
    font-display: swap;
  }
}
@supports (font-variation-settings: "wght" 900) {
  * {
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-display: swap;
  }
}





